.navigation {
  flex-grow: 1;
  align-self: flex-end;
  margin-left: 24px;
}
.personIcon {
  margin-right: 16px;
}
.btnLogOut {
  margin-right: 0;
}
.btnProfile {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
