.listItem {
  margin-left: 56px;
}

.error {
  color: red;
}

.done {
  color: #4CAF50;
  font-weight: bold;
}