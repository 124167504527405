.regionPanel {
  max-height: 40%;
}

.countryPanel {
  max-height: 60%;
}

.locationWholeContainer {
  height: calc(100vh - 64px);
}