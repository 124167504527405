.buttonContainer {
  margin-left: -16px;
}

.linksContainer {
  padding: 0 16px;
}

.linksText {
  text-align: right;
}