.container {
  padding: 12px;
}

.divider {
  width: 100%;
  margin: 12px 0;
}

.submenu {
  padding-left: 56px;
}

.submenuText {
  color: #888;
  font-size: 14px;
}