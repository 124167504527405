.container {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.tableContainer {
  overflow: hidden;
  height: calc(100vh - 112px);
}

.width4K {
  min-width: 3500px;
}