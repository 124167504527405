.menu {

}

.listItem {
  margin: 6px 0;
}

.listItemText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.primaryText {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  font-weight: 500;
}

.secondaryText {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  font-weight: 500;
}

.selected {

}