.device {
  transition: opacity ease-in 0.3s;
  z-index: 2;
}

.deviceNotSelected {
  opacity: 0.3;
}

.deviceIconSelected {
  opacity: 1;
}