.menu {
  margin-right: 16px;
  padding: 0;
}

.listItem {
  padding-left: 72px;
  margin: 0;
  border-radius: 0 30px 30px 0;
}

.primaryText {
  font-weight: 300;
}

.secondaryText {
  font-weight: 300;
  color: black;
}

.selected {
  background: linear-gradient(to right, rgba(0, 125, 255, 0.08), rgba(0, 125, 255, 0.08));
}