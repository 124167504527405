.root {
  font-size: 14px;
  width: 100%;
}

.option {
  height: 32px;
}

.addElement {
  color: #0098dd;
  font-size: 14px;
  height: 32px;
}

.input {
  padding: 0 8px;
}
