.container {
  background-image: url("Login illustration 1.svg"),
    url("Login Illustration 2.svg");
  background-position: left bottom, right bottom;
  background-size: 37%, 40%;
  background-repeat: no-repeat;
}

.card {
  margin: auto;
  padding: 32px;
  width: max-content;
  border-radius: 24px;
  min-height: 424px;
  min-width: 392px;
  display: grid;
  grid-template-columns: auto;
}
