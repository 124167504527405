.countrySelect {
  width: 100%;
}

.panel {
  padding: 16px;
}

.uploadButtonContainer {
  padding-top: 4px !important;
}