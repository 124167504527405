.icon {
  circle {
    fill: #586E8D;
  }
  path {
    stroke: #586E8D;
  }
}

.disabled {
  circle {
    fill: rgba(0, 0, 0, 0.26);
  }
  path {
    stroke: rgba(0, 0, 0, 0.26);
  }
}

.select {
  opacity: 0;
  position: absolute;
}