.initial {
  opacity: 0;
  pointer-events: none;
}

.disabled {
  display: none !important;
}

.enabled {
  opacity: 1;
}