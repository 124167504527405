.divider {
  position: relative;
  height: 90%;
  margin: 0 0 0 16px;
}

.mask {
  overflow: hidden;
  width: 20px;
  height: 100%;

  &:after {
    content: '';
    display: block;
    margin-left: -20px;
    width: 20px;
    height: 100%;
    border-radius: 12px / 125px;
    box-shadow: 0 0 8px #aaa;
  }
}