.listItem {
  padding-left: 72px;
  height: 40px;
  box-shadow: none;
}

.borderRadius {
  border-radius: 0 30px 30px 0;
}

.noBorderRadius {
  border-radius: 0;
}

.menu {
  padding: 0 16px 0 0;
}

.title {
  margin: 0 0 8px 0;
}