.submit {
  background-color: #FF9800;
  color: #fff !important;
}

.submitChip {
  background-color: #FFAA00;
  color: #fff;
}

.approve {
  background-color: #4CAF50;
  color: #fff !important;
}

.approveChip {
  background-color: #3C9F40;
  color: #fff;
}

.reject {
  background-color: #FF606F;
  color: #fff !important;
}

.rejectChip {
  background-color: #DF404F;
  color: #fff !important;
}