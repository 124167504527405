.select {
  opacity: 0;
  position: absolute;
}

.on {
  color: #3B873E;
}

.off {
  color: #E31B0C;
}