.formContainer {
  height: calc(60vh - 160px);
  padding: 16px;
}

.title {
  padding-left: 0;
  margin: 8px 0 8px 8px;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #586e8d;
}