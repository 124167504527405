.area {
  transition: opacity 0.3s;
}

.invisible {
  opacity: 0 !important;
}

.notSelected {
  opacity: 0.5;
}

.selected {
  opacity: 1;
}