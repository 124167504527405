.wrapper {
  position: relative;
}

.chip {
  margin: 2px;
}

.label {
  background-color: white;
  padding: 0 5px;
}

.select {
  min-height: 46px;
  height: auto;
  border-radius: 3px;
}

.selectContent {
  padding: 4px;
}