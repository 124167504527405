.panel {
  position: relative;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex-wrap: nowrap;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
  background-color: rgb(255, 255, 255);

  &:empty {
    display: none;
  }
}