.inputBox {
  margin: 12px 12px 6px 12px;
  width: 100%;
}

.disabledInput {
  color: rgba(0, 0, 0, 0.38);
}

.statusInput {
  text-transform: capitalize;
  color: #3397FF;
}
