.gridRoot {
  overflow: hidden;
  height: 100%;
  max-height: calc(100% - 64px);
}

.leftPanel {
  z-index: 2;
  flex: 1 0 384px;
}

.centralPanel {
  z-index: 1;
  flex: 1 1 auto;
}

.rightPanel {
  z-index: 3;
  max-width: 30%;
}
