.gridConainer {
  flex-grow: 1;
  width: 100%;
  margin-bottom: 16px;
}
.gridItem {
  width: 100%;
  padding: 16px 16px 0 16px;
}
.fields {
  width: 100%;
}
.inputLabel {
  background-color: #ffffff;
  padding: 0 5px;
}

.wrapper {
  max-height: calc(60vh - 160px);
}
