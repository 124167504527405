.image {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-image: url('./assets/computer.svg');
  background-repeat: no-repeat;
  background-position: center bottom;
}

.exportedMenuImageClass {
  background-image: url('./assets/computer.svg');
  background-repeat: no-repeat;
  background-position: center bottom;
}