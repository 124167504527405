.gridRoot {
  overflow: hidden;
  max-height: calc(100% - 64px);
}

.panel {
  max-height: 100%;
}

.contentPanel {
  max-height: 100%;
  overflow: auto;
}
