.wrapper {
  position: relative;
}

.chip {
  margin: 2px;
}

.chipsContainer {
  margin: 12px 0;
}

.label {
  background-color: white;
  padding: 0 5px;
  transform: translate(0, -6px) scale(0.75)
}
