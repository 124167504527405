.paper {
  height: 100%;
}

.menuList {
  padding: 0;
}

.bigMenuItem {
  height: 64px;
}

.smallMenuItem {
  height: 32px;
}

.menuItem {
  font-size: 14px;
  width: 100%;
  box-shadow: inset 0px -1px 0px #E0E0E0;
}

.menuItemRoot {
  overflow: hidden;
}

.menuItemText {
  width: 9999px;
  font-size: 14px;
}