.smallPanel {
  flex: 0 0 auto;
}

.restSpacePanel {
  flex: 1 1 auto;
}

.wholeSpacePanel {
  height: 100%;
}

.addScroll {
  overflow-y: auto;
}

.noWrap {
  flex-wrap: nowrap;
}