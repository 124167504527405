.indicator {
  width: 4rem;
  height: 4px;
  border-radius: 99px 99px 0 0,
}

.tab {
  font-weight: bold;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.46px;
}

.tabsAppBar {
  padding-left: 16px;
  box-shadow: none;
}