.textArea {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  -moz-appearance: none;
  resize: none;
  font-size: 14px;
  padding: 4px;
}

.suppressNegativeMargin {
  overflow: hidden;
}

.text {
  color: #008BCA;
  text-transform: capitalize;
}

.errorText {
  text-transform: capitalize;
  color: red;
}