.container {
  padding: 16px;
}

.line {
  line-height: 32px;
}

.coordinatesBlock {
  padding: 12px 0;
}