.image {
  height: 80%;
}

.text {
  color: #586E8D;
  transform: translateY(-150%);
}

.noItemsContainer {
  height: 100%;
  transform: translateY(-5%);
}